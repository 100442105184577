<template>
	<S2SForm title="Bank Statement">
		<S2SCard v-if="model.id && editMode" :title="'Make a bank statement entry adjustment for ' + model.id">
			<S2SFormGenerator
				ref="bankSatementAdjustmentForm"
				:schema="bankStatementEntrySchema"
				:data="model.adjust"
				:apiLookup="lookupsApi"
			></S2SFormGenerator>
			<v-flex xs12 class="text-xs-left">
				<v-btn color="accent" @click="onSubmit" class="mr-2">Submit</v-btn>
				<v-btn color="accent2" @click="editMode = false">Cancel</v-btn>

				<v-layout class="text-xs-right float-right">
					<strong>Total:</strong>
					{{ formatCurrency(adjustTotal(editAmount, model.adjust.adjustmentAmount)) }}
				</v-layout>
			</v-flex>
		</S2SCard>
		<br />
		<v-flex class="d-flex justify-end mb-5 mr-5" v-if="!editMode">
			<v-checkbox class="mr-4" label="Show All" v-model="isShowAll" @change="onShowAll($event)"></v-checkbox>

			<v-text-field
				v-model="search"
				append-icon="search"
				@click:append="fetchStatementsById(search)"
				@click:clear="fetchStatementsById()"
				@keydown.enter="fetchStatementsById(search)"
				:rules="[v => /\s||\d+$/.test(v) || 'Id format incorrect']"
				label="Search by id"
				single-line
				class="mr-4"
				clearable
			></v-text-field>

			<v-btn @click="onRefresh($event)">
				<v-icon>refresh</v-icon>
			</v-btn>
			<br />
		</v-flex>
		<v-data-table :headers="headers" :items="bankStatementEntries" hide-default-footer class="elevation-1" :loading="status.loading">
			<template v-slot:item.statementdate="{ item }">
				<span class="text-no-wrap">{{ formatDateTime(item.statementdate) }}</span>
			</template>
			<template v-slot:item.amount="{ item }">
				<span class="text-no-wrap">{{ formatCurrency(item.amount) }}</span>
			</template>
			<template v-slot:item.amountAdjustment="{ item }">
				<span class="text-no-wrap">{{ formatCurrency(item.amountAdjustment) }}</span>
			</template>
			<template v-slot:item.amountWriteoff="{ item }">
				<span class="text-no-wrap">{{ formatCurrency(item.amountWriteoff) }}</span>
			</template>
			<template v-slot:item.amountTotal="{ item }">
				<span class="text-no-wrap">{{ formatCurrency(adjustTotal(item.amount, item.amountAdjustment)) }}</span>
			</template>
			<template v-slot:item.bankstatementstatus="{ item }">
				<span v-if="item.bankstatementstatus > 0" class="error--text">Closed</span>
				<span v-else class="success--text">Open</span>
			</template>
			<template v-slot:item.action="{ item }">
				<v-btn color="accent" @click="onAdd(item.id)" text><v-icon>mdi-plus-circle</v-icon></v-btn>
				<v-btn @click="onEdit(item)" text>
					<v-icon>edit</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Status } from "@/store/utils";
import { LookupsApi } from "@/api/lookups-api";
import bankStatementEntrySchema from "@/static/config/forms/bankStatementEntry-schema.json";
import { BankAdjustmentBody } from "../../api/safes-api";
import { Object } from "lodash";
import { Utils } from "@/utils";
import { ErrorBag } from "vee-validate";
import { isError, isObject } from "util";
import { state } from "../../store/organisations/users";

export default Vue.extend({
	name: "BankStatementEntry",

	data: function() {
		return {
			bankStatementEntrySchema: bankStatementEntrySchema,
			lookupsApi: LookupsApi.fetchLookups,
			model: { id: 0, adjust: { bankstatementStatus: 0, adjustmentAmount: 0, adjustmentDescription: "", adjustmentReasonId: null } },
			editMode: false,
			search: "",
			editAmount: 0,

			isShowAll: false,

			headers: [
				{ text: "Id", value: "id", align: "left" },
				{ text: "Statement Date", value: "statementdate", type: "dateTime" },
				{ text: "Description", value: "description", align: "left" },
				{ text: "Amount", value: "amount", type: "currency" },
				{ text: "Adjustment", value: "amountAdjustment", type: "currency", sortable: false },
				{ text: "Writeoff", value: "amountWriteoff", type: "currency", sortable: false },
				{ text: "Total", value: "amountTotal", type: "currency" },
				{ text: "Ref Adjustment", value: "descriptionAdjustment", align: "left" },
				{ text: "Related Id", value: "relatedId", align: "left" },
				{ text: "Status", value: "bankstatementstatus", align: "center" },
				{ text: "Action", value: "action", type: "action", align: "center" }
			]
		};
	},

	computed: {
		bankStatementEntries: function() {
			return this.$store.state.safes.bankStatementEntries;
		},
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	mounted: function() {
		this.$store.dispatch("safes/fetchBankStatementEntries");
	},

	methods: {
		formatDateTime(val) {
			return Utils.formatText(val, Utils.TextType.DATE_YYYY_MM_DD);
		},
		formatCurrency(val) {
			return Utils.formatText(val, Utils.TextType.CURRENCY);
		},
		adjustTotal(amount, amountAdjusted) {
			return parseFloat(amount) + parseFloat(amountAdjusted);
		},
		onAdd(id) {
			this.$store.dispatch("safes/createBankStatementEntry", id);
		},
		async onEdit(item) {
			this.editMode = true;
			this.model.id = item.id;
			this.model.adjust.bankstatementStatus = item.bankstatementstatus;
			this.model.adjust.adjustmentAmount = item.amountAdjustment;
			this.model.adjust.adjustmentDescription = item.descriptionAdjustment;
			this.model.adjust.amountWriteoff = item.amountWriteoff;
			this.model.adjust.adjustmentReasonId = item.adjustmentReasonId === 0 ? null : item.adjustmentReasonId;
			this.editAmount = item.amount;
		},
		doValidation(item, lookup) {
			let errors = false;
			/* if (isObject(item)) {
        Array.from(lookup.validate, (key: any) => {
         if (item.hasOwnProperty(key)) {
          if (item[key] == null || item[key] === lookup.rules[key]) {
           errors = true;
          }
         }
        });
       } */

			return errors;
		},
		onSubmit() {
			let form = this.$refs["bankSatementAdjustmentForm"];
			let bankAdjustmentObject = form.model;
			let id = this.model.id;

			let validateArray = ["adjustmentReasonId"];
			let validateRules = {
				adjustmentReasonId: null
			};

			// minor tweak for v-select validation
			let invalidForm = this.doValidation(this.model.adjust, { validate: validateArray, rules: validateRules });

			if (invalidForm) {
				let confirmed = confirm("Please choose an Adjustment Reason.");
				if (!confirmed) {
					this.editMode = false;
				}
			} else {
				// update entry
				this.$store.dispatch("safes/updateBankStatementEntry", { id, bankAdjustmentObject });
				this.editMode = false;
			}
		},
		onRefresh(event) {
			event.target.classList.add("refresh");

			setTimeout(function() {
				event.target.classList.remove("refresh");
			}, 1000);

			this.$store.dispatch("safes/fetchBankStatementEntries");
		},
		onShowAll(isShowAll) {
			let status = isShowAll ? -1 : 0;
			this.$store.dispatch("safes/fetchBankStatementEntries", status);
		},
		fetchStatementsById(id) {
			if (id) {
				this.$store.dispatch("safes/fetchBankStatementEntriesById", id);
			} else {
				this.$store.dispatch("safes/fetchBankStatementEntries");
			}
		}
	}
});
</script>

<style>
button.refresh i,
i.refresh {
	animation: refresh 1s ease-in-out;
}

@keyframes refresh {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
